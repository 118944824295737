<template>  
    <v-container>  
        <v-snackbar v-model="showPopup" color="red" top>  
            This action cannot be undone. Are you sure?  
            <v-btn color="white" text @click="showPopup = false">Cancel</v-btn>  
            <v-btn color="white" text @click="confirmDelete">Confirm</v-btn>  
        </v-snackbar>  
  
        <v-snackbar v-model="showSuccess" color="green" top>  
            Your account deletion request has been successful.  
            <v-btn color="white" text @click="showSuccess = false">OK</v-btn>  
        </v-snackbar>  
  
        <v-card>  
            <v-card-title class="headline text-center">Delete Account from {{ TITLE }}</v-card-title>  
            <v-card-text>  
                <p class="red-text">To delete your account, please fill out the form below. Please note the following steps:</p>  
                <ul class="pl-5">  
                    <li>Fill out your name and email address.</li>  
                    <li>Check the box to confirm that you agree to the Terms and Conditions of {{ TITLE }}.</li>  
                    <li>Click the "Delete Account" button.</li>  
                </ul>  
                <p class="red-text">We will delete the following types of data associated with your account:</p>  
                <ul class="pl-5">  
                    <li>Personal information provided during registration.</li>  
                    <li>Any data associated with your account, such as preferences or settings.</li>  
                </ul>  
                <p class="red-text">Please note that some data may be retained for a certain period of time for legal or administrative purposes.</p>  
            </v-card-text>  
            <v-card-actions>  
                <v-form>  
                    <v-text-field label="Name" required></v-text-field>  
                    <v-text-field label="Email" type="email" required :rules="emailRules"></v-text-field>  
                    <v-checkbox :label="'I agree to the Terms and Conditions of ' + TITLE" v-model="agreeChecked"></v-checkbox>  
                    <v-btn :disabled="!agreeChecked" color="green" light class="delete-btn" @click="submitForm">Delete Account</v-btn>  
                </v-form>  
            </v-card-actions>  
        </v-card>  
    </v-container>  
</template>  
  
<script>  
export default {  
    data() {  
        return {  
            showPopup: false,  
            showSuccess: false,  
            agreeChecked: false,  
            TITLE: '',  
            emailRules: [  
                v => !!v || 'E-mail is required',  
                v => /.+@.+/.test(v) || 'E-mail must be valid'  
            ],  
        };  
    },  
    created() {  
        this.TITLE = window.vueConfigProvider.TITLE;  
    },  
    methods: {  
        submitForm() {  
            if (this.agreeChecked) {  
                this.showPopup = true;  
            } else {  
                alert("You must agree to the terms and conditions before deleting your account.");  
            }  
        },  
        confirmDelete() {  
            this.showPopup = false;
            setTimeout(() => {  
                this.showSuccess = true;  
            }, 2000);  
            setTimeout(() => {  
                this.showSuccess = false;  
            }, 4000);  
        },  
    },  
};  
</script>  
  
<style scoped>  
.red-text {  
    color: red;  
}  
.pl-5 {  
    padding-left: 1.25rem;  
}  
.delete-btn:disabled {  
    opacity: 0.65;  
}  
</style>  
